// App/index.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../components/dashboard/index.js";
import Presentation from "../../components/presentation/index.js";
import { useTheme } from "../../contexts/ThemeContext.js";
import logo from "../../images/flame.gif";
import "../../scss/app.scss";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import flameBig from "../../images/flame-big.gif";

import {
  ConnectWallet,
  useAddress,
  useUser,
  useChainId,
  useDisconnect
} from "@thirdweb-dev/react";

import { snapshot as _0x279c53df917579bb454b61555982dac1750187d9Snapshot } from "../../utils/snapshots/x279c53df917579bb454b61555982dac1750187d9.js";
import { snapshot as _0x8c6def540b83471664edc6d5cf75883986932674Snapshot } from "../../utils/snapshots/x8c6def540b83471664edc6d5cf75883986932674.js";
import { snapshot as _0x895688bf87d73cc7da27852221799d31b027e300Snapshot } from "../../utils/snapshots/x895688bf87d73cc7da27852221799d31b027e300.js";
import { snapshot as _0x8cb05890b7a640341069fb65dd4e070367f4d2e6Snapshot } from "../../utils/snapshots/x8cb05890b7a640341069fb65dd4e070367f4d2e6.js";
import { snapshot as _0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93Snapshot } from "../../utils/snapshots/xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93.js";
import { snapshot as _0x7b390a46c868ccebf2730121faf777129b47c97cSnapshot } from "../../utils/snapshots/x7b390a46c868ccebf2730121faf777129b47c97c.js";
import { snapshot as _0x3266b70962a61f1917f79c387749e3bd95a9ff52Snapshot } from "../../utils/snapshots/x3266b70962a61f1917f79c387749e3bd95a9ff52.js";
import { snapshot as _0x7912a656ece2bc669d4116ad8e9495f722d92d76Snapshot } from "../../utils/snapshots/x7912a656ece2bc669d4116ad8e9495f722d92d76.js";
import { snapshot as _0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9Snapshot } from "../../utils/snapshots/xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9.js";

import { http } from "viem";
import { DelegateV1, DelegateV2 } from "@delegatexyz/sdk";

const RPC_URL = "https://eth-mainnet.g.alchemy.com/v2/x2clG0iamQo50Ymz3JD1IuTkvf0MGRO1";
const v2 = new DelegateV2(http(RPC_URL))
const v1 = new DelegateV1(http(RPC_URL))


const contractSnapshots = {
  "0x279c53df917579bb454b61555982dac1750187d9": _0x279c53df917579bb454b61555982dac1750187d9Snapshot,
  "0x8c6def540b83471664edc6d5cf75883986932674": _0x8c6def540b83471664edc6d5cf75883986932674Snapshot,
  "0x895688bf87d73cc7da27852221799d31b027e300": _0x895688bf87d73cc7da27852221799d31b027e300Snapshot,
  "0x8cb05890b7a640341069fb65dd4e070367f4d2e6": _0x8cb05890b7a640341069fb65dd4e070367f4d2e6Snapshot,
  "0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93": _0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93Snapshot,
  "0x7b390a46c868ccebf2730121faf777129b47c97c": _0x7b390a46c868ccebf2730121faf777129b47c97cSnapshot,
  "0x3266b70962a61f1917f79c387749e3bd95a9ff52": _0x3266b70962a61f1917f79c387749e3bd95a9ff52Snapshot,
  "0x7912a656ece2bc669d4116ad8e9495f722d92d76": _0x7912a656ece2bc669d4116ad8e9495f722d92d76Snapshot,
  "0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9": _0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9Snapshot,
};

const TruthDashboard = () => {
  const navigate = useNavigate();
  const [tokens, setTokens] = useState([]);
  const [tokensAggregated, setTokensAggregated] = useState([]);
  const [opensea, setOpensea] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [data, setData] = useState([]);
  const [isEscrowing, setIsEscrowing] = useState(false);
  const [isBurned, setIsBurned] = useState(false);
  const [apiDown, setApiDown] = useState(false);
  const [isGallery, setIsGallery] = useState(false);
  const [isBurnedValue, setIsBurnedValue] = useState(false);
  const [chain, setChain] = useState(5);
  const [alreadyUsedMessage, setAlreadyUsedMessage] = useState("");
  const address = useAddress();
  const chainId = useChainId();
  const { isLoggedIn, user: thirdwebUser } = useUser();
  const disconnect = useDisconnect();

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    isLoading,
  } = useAuth0();
  const { theme } = useTheme();

  useEffect(() => {
    setChain(chainId);
  }, [chainId]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const lowercaseAddress = address ? address.toLowerCase() : null;
      fetchData(user, lowercaseAddress);
    }
  }, [isAuthenticated, isLoading, user, address]);

  useEffect(() => {
    if (isLoggedIn && isAuthenticated) {
      const lowercaseAddress = address ? address.toLowerCase() : null;
      fetchData(user, lowercaseAddress);
    }
  }, [isLoggedIn, isAuthenticated, refreshData]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/")
    }
  }, [isAuthenticated, isLoading]);

  const graphqlEndpoint =
    "https://api.thegraph.com/subgraphs/name/0x-g/truth-labs";

  const aggregateTokensForAllWallets = async (wallets) => {
    try {
      const tokensFromAllWallets = await Promise.all(wallets.map(async (wallet) => {
        const tokensForWallet = await getTokensAggregate(wallet);
        return tokensForWallet;
      }));

      const aggregatedTokens = tokensFromAllWallets.flat();
      setTokensAggregated(aggregatedTokens);
    } catch (error) {
      console.error("Error aggregating tokens:", error);
    }
  };

  const getTokensAggregate = async (wallet) => {
    try {
      const queryResponse = await fetchTokensAndDeposits(wallet);
      const processedData = processQueryResponse(queryResponse);

      const depositMap = queryResponse.deposits.reduce((acc, deposit) => {
        const key = `${deposit.token}-${deposit.tokenId}`;
        acc[key] = deposit;
        return acc;
      }, {});

      const walletData = await postToGetWallet(wallet, processedData);

      const incomingDelegationsV1 = await v1.getDelegationsByDelegate(wallet);
      const incomingDelegationsV2 = await v2.getIncomingDelegations(wallet);

      const delegatedWallets = [
        ...incomingDelegationsV1.map(delegation => delegation.vault),
        ...incomingDelegationsV2.map(delegation => delegation.from)
      ];

      const seen1155Tokens = new Map();

      const walletDatawithEscrow = walletData.map((item) => {
        const escrowKey = `${item.contract}-${item.id}`;
        let inEscrow = item.type === 1155 ? (seen1155Tokens.has(escrowKey) ? depositMap[escrowKey] ? true : false : (seen1155Tokens.set(escrowKey, true), false)) : depositMap[escrowKey] ? true : false;

        let inSnapshot = true;
        let allowedAmount = item.amount;

        let price = item.price;

        const contractData = contractSnapshots[item.contract.toLowerCase()];
        const isInSnapshotForWallet = (snapshotData, walletAddress) => {
          if (item.type === 721) {
            return snapshotData && snapshotData[item.id.toString()] && snapshotData[item.id.toString()].toLowerCase() === walletAddress.toLowerCase();
          } else if (item.type === 1155) {
            const entry = snapshotData && snapshotData[item.id.toString()] && snapshotData[item.id.toString()].find(e => e.wallet.toLowerCase() === walletAddress.toLowerCase());
            return !!entry;
          }
          return false;
        };

        inSnapshot = isInSnapshotForWallet(contractData, wallet);
        if (!inSnapshot) {
          for (const delegatedWallet of delegatedWallets) {
            inSnapshot = isInSnapshotForWallet(contractData, delegatedWallet);
            if (inSnapshot) break;
          }
        }

        const calculateAllowedAmount = (snapshotData, walletAddress, item, inEscrow, depositMap, escrowKey) => {
          let allowedAmount = 0;
          if (item.type === 721) {
            allowedAmount = inEscrow ? 0 : 1;
          } else if (item.type === 1155 && snapshotData && snapshotData[item.id.toString()]) {
            const entry = snapshotData[item.id.toString()].find(e => e.wallet.toLowerCase() === walletAddress.toLowerCase());
            if (entry) {
              if (depositMap[escrowKey] && depositMap[escrowKey].amount) {
                allowedAmount = !inEscrow ? Math.max(0, entry.quantity - depositMap[escrowKey].amount) : 0;
              } else {
                allowedAmount = Math.min(entry.quantity, item.amount);
              }
            }
          }
          return allowedAmount;
        };

        if (item.type === 721 || item.type === 1155) {
          allowedAmount = calculateAllowedAmount(contractData, wallet, item, inEscrow, depositMap, escrowKey);

          if (allowedAmount === 0 && item.type === 1155) {
            for (const delegatedWallet of delegatedWallets) {
              const delegatedAllowedAmount = calculateAllowedAmount(contractData, delegatedWallet, item, inEscrow, depositMap, escrowKey);
              if (delegatedAllowedAmount > 0) {
                allowedAmount = delegatedAllowedAmount;
                break;
              }
            }
          }
        }

        return { ...item, escrow: inEscrow, inSnapshot: inSnapshot, allowedAmount: allowedAmount, price: price }
      });


      const adjustedWalletData = walletDatawithEscrow.map(item => {
        if (item.allowedAmount === 0 && item.burnable) {
          return { ...item, escrow: true };
        }
        return item;
      });


      const filteredWalletData = adjustedWalletData.filter(item => {
        return !(item.type === 1155 && item.amount === 0 && !item.escrow);
      });

      return filteredWalletData;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getTokens = async (wallet) => {
    try {
      const queryResponse = await fetchTokensAndDeposits(wallet);
      const processedData = processQueryResponse(queryResponse);

      const depositMap = queryResponse.deposits.reduce((acc, deposit) => {
        const key = `${deposit.token}-${deposit.tokenId}`;
        acc[key] = deposit;
        return acc;
      }, {});

      const walletData = await postToGetWallet(wallet, processedData);

      const incomingDelegationsV1 = await v1.getDelegationsByDelegate(wallet);
      const incomingDelegationsV2 = await v2.getIncomingDelegations(wallet);

      const delegatedWallets = [
        ...incomingDelegationsV1.map(delegation => delegation.vault),
        ...incomingDelegationsV2.map(delegation => delegation.from)
      ];

      const seen1155Tokens = new Map();

      const walletDatawithEscrow = walletData.map((item) => {
        const escrowKey = `${item.contract}-${item.id}`;
        let inEscrow = item.type === 1155 ? (seen1155Tokens.has(escrowKey) ? depositMap[escrowKey] ? true : false : (seen1155Tokens.set(escrowKey, true), false)) : depositMap[escrowKey] ? true : false;

        let inSnapshot = true;
        let allowedAmount = item.amount;

        let price = item.price;

        const contractData = contractSnapshots[item.contract.toLowerCase()];
        const isInSnapshotForWallet = (snapshotData, walletAddress) => {
          if (item.type === 721) {
            return snapshotData && snapshotData[item.id.toString()] && snapshotData[item.id.toString()].toLowerCase() === walletAddress.toLowerCase();
          } else if (item.type === 1155) {
            const entry = snapshotData && snapshotData[item.id.toString()] && snapshotData[item.id.toString()].find(e => e.wallet.toLowerCase() === walletAddress.toLowerCase());
            return !!entry;
          }
          return false;
        };

        inSnapshot = isInSnapshotForWallet(contractData, wallet);
        if (!inSnapshot) {
          for (const delegatedWallet of delegatedWallets) {
            inSnapshot = isInSnapshotForWallet(contractData, delegatedWallet);
            if (inSnapshot) break;
          }
        }

        const calculateAllowedAmount = (snapshotData, walletAddress, item, inEscrow, depositMap, escrowKey) => {
          let allowedAmount = 0;
          if (item.type === 721) {
            allowedAmount = inEscrow ? 0 : 1;
          } else if (item.type === 1155 && snapshotData && snapshotData[item.id.toString()]) {
            const entry = snapshotData[item.id.toString()].find(e => e.wallet.toLowerCase() === walletAddress.toLowerCase());
            if (entry) {
              if (depositMap[escrowKey] && depositMap[escrowKey].amount) {
                allowedAmount = !inEscrow ? Math.max(0, entry.quantity - depositMap[escrowKey].amount) : 0;
              } else {
                allowedAmount = Math.min(entry.quantity, item.amount);
              }
            }
          }
          return allowedAmount;
        };

        if (item.type === 721 || item.type === 1155) {
          allowedAmount = calculateAllowedAmount(contractData, wallet, item, inEscrow, depositMap, escrowKey);

          if (allowedAmount === 0 && item.type === 1155) {
            for (const delegatedWallet of delegatedWallets) {
              const delegatedAllowedAmount = calculateAllowedAmount(contractData, delegatedWallet, item, inEscrow, depositMap, escrowKey);
              if (delegatedAllowedAmount > 0) {
                allowedAmount = delegatedAllowedAmount;
                break;
              }
            }
          }
        }

        return { ...item, escrow: inEscrow, inSnapshot: inSnapshot, allowedAmount: allowedAmount, price: price }
      });


      const adjustedWalletData = walletDatawithEscrow.map(item => {
        if (item.allowedAmount === 0 && item.burnable) {
          return { ...item, escrow: true };
        }
        return item;
      });


      const filteredWalletData = adjustedWalletData.filter(item => {
        return !(item.type === 1155 && item.amount === 0 && !item.escrow);
      });


      const sortedWalletData = filteredWalletData.sort((a, b) => b.price - a.price);

      setTokens(sortedWalletData);

    } catch (error) {
      console.log("Error:", error);
    }
  };


  async function fetchTokensAndDeposits(wallet) {
    const validTokens = [
      "0x895688bf87d73cc7da27852221799d31b027e300",
      "0x8c6def540b83471664edc6d5cf75883986932674",
      "0x279c53df917579bb454b61555982dac1750187d9",
      "0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93",
      "0x8cb05890b7a640341069fb65dd4e070367f4d2e6",
      "0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9",
      "0x3266b70962a61f1917f79c387749e3bd95a9ff52",
      "0x7912a656ece2bc669d4116ad8e9495f722d92d76",
      "0x7b390a46c868ccebf2730121faf777129b47c97c"
    ];

    const specialContract = "0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9";
    const specialTokenId = 4;

    const query = `
      {
        tokens(first: 1000, where: {owner_contains_nocase: "${wallet}"}) {
          id
          token
          tokenId
          amount
          owner
        },
        deposits(first: 1000, where: {owner_contains_nocase: "${wallet}"}) {
          id
          token
          tokenId
          amount
          owner
          burnt
        }
      }
    `;

    const response = await axios.post(graphqlEndpoint, { query });
    const data = response.data.data;

    data.tokens = data.tokens.filter(token => validTokens.includes(token.token) && !(token.token === specialContract && token.tokenId === specialTokenId));
    data.deposits = data.deposits.filter(deposit => validTokens.includes(deposit.token) && !(deposit.token === specialContract && deposit.tokenId === specialTokenId));

    return data;
  }

  function processQueryResponse(response) {
    const { tokens, deposits } = response;

    let tokenMap = {};
    tokens.forEach((token) => {
      if (!tokenMap[token.token]) {
        tokenMap[token.token] = {
          token: token.token,
          tokenData: [],
        };
      }
      tokenMap[token.token].tokenData.push({
        tokenId: token.tokenId,
        tokenAmount: token.amount,
        escrow: false,
      });
    });

    deposits.forEach((deposit) => {
      if (!tokenMap[deposit.token]) {
        tokenMap[deposit.token] = {
          token: deposit.token,
          tokenData: [],
        };
      }
      tokenMap[deposit.token].tokenData.push({
        tokenId: deposit.tokenId,
        tokenAmount: deposit.amount,
        escrow: true,
      });
    });

    let processedData = Object.values(tokenMap);

    return { tokens: processedData };
  }

  async function postToGetWallet(wallet, data) {
    const config = {
      method: "get",
      url: "https://burnitall.biginc.business/getwallet/" + wallet,
      data: data,
    };

    const response = await axios.request(config);
    return response.data.data;
  }

  const getOpensea = async (wallet) => {
    try {
      let config = {
        method: "get",
        url: "https://burnitall.biginc.business/opensea/" + wallet,
      };

      const response = await axios.request(config);

      setOpensea(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getData = async (userId, address) => {
    try {
      let config = {
        method: "get",
        url: "https://burnitall.biginc.business/user/" + userId,
      };

      const response = await axios.request(config);

      setData(response.data);

      const userMetadata = response.data.data.user_metadata;

      if (userMetadata) {

        if (response.data.data.user_metadata.wallets) {
          await aggregateTokensForAllWallets(userMetadata.wallets);
        }

        if (response.data.data.user_metadata.discord) {
          setDiscordUser(response.data.data.user_metadata.discord);
        }
      }

      getTokens(address);
      // getOpensea(address);

    } catch (error) {
      console.error("Error:", error);
      setApiDown(true)
    }
  };

  const fetchData = async (u, a) => {
    try {
      if (u) await getData(u.sub, a);
    } catch (error) {
      console.log("Error in fetchData:", error);
    }
  };

  const fetchApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error("Failed to retrieve access token", error);
    }
  };

  const home = () => {
    window.location.href = "https://app.trutharts.com/"
  }

  const [discordUser, setDiscordUser] = useState(null);

  const fragment = new URL(window.location.href).hash.substring(1);
  const params = new URLSearchParams(fragment);
  const discordAccessToken = params.get("access_token");

  const handleConnectDiscord = () => {
    let currentURL =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.location.href =
      "https://discord.com/api/oauth2/authorize?client_id=1195144641008390174&redirect_uri=" +
      encodeURIComponent(currentURL) +
      "&response_type=token&scope=identify";
  };

  async function getDiscordUsername(discordAccessToken) {
    const response = await fetch("https://discord.com/api/users/@me", {
      headers: { Authorization: `Bearer ${discordAccessToken}` },
    });
    const user = await response.json();
    setDiscordUser(user);
    return user.username;
  }

  const setAchievementDiscord = async (oauth) => {
    const token = await fetchApi();

    try {
      let config = {
        method: "post",
        url: "https://burnitall.biginc.business/connectdiscord",
        data: { discordToken: oauth, userToken: token },
      };
      await axios.request(config);
      const lowercaseAddress = address ? address.toLowerCase() : null;
      fetchData(user, lowercaseAddress);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const update = async (key, data) => {
    const token = await fetchApi();

    try {
      let config = {
        method: "post",
        url: "https://burnitall.biginc.business/setmetadata ",
        data: {
          token: token,
          metadata: [
            {
              type: key,
              data: data,
            },
          ],
        },
      };

      const response = await axios.request(config);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (discordAccessToken && isAuthenticated) {
      getDiscordUsername(discordAccessToken).then((data) => {
        update("discord", data).then(() => {
          setAchievementDiscord(discordAccessToken);
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        });
      });
    }
  }, [isAuthenticated, discordAccessToken]);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getUser = async () => {
    try {
      const token = await fetchApi();

      let config = {
        method: "post",
        url: "https://burnitall.biginc.business/setwallet",
        withCredentials: true,
        data: {
          token: token,
        },
      };

      const response1 = await axios.request(config);

    } catch (error) {
      if (error.response.data.message) {
        setAlreadyUsedMessage(error.response.data.message)
      }
      disconnect();
    }
  };

  useEffect(() => {
    if (address && user && (isLoggedIn || thirdwebUser) && isAuthenticated) {
      getUser();
    }
  }, [address, isLoggedIn, isAuthenticated, thirdwebUser]);

  if (apiDown) {
    return (
      <>
        <div id="Connect-Wallet-Lander" className={`login app`}>
          <div className="flame">
            <img src={flameBig} alt="flame"></img>
          </div>
          <div className="copy">
            <img src={logo} alt="Logo" className="login-image"></img>
            <>
              {/* <h2>burn limit reached</h2> */}
              <p className="large">We have completed the sharedrop process with Republic and have sent all SAFEs to eligible, participating burners who were approved. Please keep an eye on your emails for an email from Republic notifying you that your SAFE is in your Republic account. We will also be airdropping the burned collection on base soon.</p>
            </>
            {/* <button
              className="button main-action"
              // style={{opacity:0.5, cursor:"not-allowed"}}
              onClick={() => setIsGallery(true)}
            >Access Dashboard</button> */}
          </div>
        </div>
      </>
    );
  }

  if (data.length === 0 || isLoading) {
    return (
      <div className={`login app`}>
        <div>
          <img src={logo} alt="Logo" className="login-image"></img>
        </div>
      </div>
    );
  }

  if (!address || !isLoggedIn || chain !== 1) {
    return (
      <>
        <div id="Connect-Wallet-Lander" className={`login app`}>
          <div className="flame">
            <img src={flameBig} alt="flame"></img>
          </div>
          <div className="copy">
            <img src={logo} alt="Logo" className="login-image"></img>
            {!address && !alreadyUsedMessage && (
              <>
                <h2>Connect your wallet</h2>
                <p className="large">Connect your wallet to access your Operation Resurrection Dashboard</p>
              </>
            )}
            {!address && alreadyUsedMessage && (
              <>
                <h2>Uh oh&hellip;</h2>
                <p className="large">{alreadyUsedMessage}. Connect a different wallet or used existing email to sign in.</p>
              </>
            )}
            {address && !isLoggedIn && (
              <>
                <h2>Sign In</h2>
                <p className="large">Confirm a signature from your wallet. This is merely to confirm your control of the wallet and will not trigger any transactions on-chain.</p>
                <button className="disconnect-button" onClick={disconnect}>use a different wallet</button>
              </>
            )}
            <ConnectWallet
              className="connect-wallet-login"
              id="Connect-Wallet"
              btnTitle={"Connect Wallet"}
              modalTitleIconUrl={""}
              showThirdwebBranding={false}
              modalTitle={""}
              switchToActiveChain={true}
              modalSize={"compact"}
              auth={{
                loginOptional: false,
              }}
            />
          </div>
        </div>
      </>
    );
  }


  const escrow = () => {
    setIsEscrowing(true);
  }

  const onEscrowReset = () => {
    setIsEscrowing(false);
  };

  const onBurnComplete = (value) => {
    setIsBurned(true);
    setIsBurnedValue(value)
    getTokens(address);
  };

  const resetBurnState = () => {
    setIsBurned(false);
  };

  return (
    <div className={`app theme-${theme}`}>
      {isGallery ? (
        <>
          <Dashboard
            tokens={tokens}
            opensea={opensea}
            data={data}
            onLogout={handleLogout}
            isEscrowing={isEscrowing}
            onEscrowReset={onEscrowReset}
            isBurned={isBurned}
            resetBurnState={resetBurnState}
            isBurnedValue={isBurnedValue}
            tokensAggregated={tokensAggregated}
          />
          <Presentation
            tokensAggregated={tokensAggregated}
            opensea={opensea}
            data={data}
            discordUser={discordUser}
            onConnectDiscord={handleConnectDiscord}
            onMetadataUpdated={() => setRefreshData((prev) => !prev)}
            onEscrow={escrow}
            onBurnComplete={onBurnComplete}
          />
        </>
      ) : (
        <div id="Connect-Wallet-Lander" className={`login app`}>
          <div className="flame">
            <img src={flameBig} alt="flame"></img>
          </div>
          <div className="copy">
            <img src={logo} alt="Logo" className="login-image"></img>
            <>
              {/* <h2>burn limit reached</h2> */}
              <p className="large">We have completed the sharedrop process with Republic and have sent all SAFEs to eligible, participating burners who were approved. Please keep an eye on your emails for an email from Republic notifying you that your SAFE is in your Republic account. We will also be airdropping the burned collection on base soon.</p>
            </>
            <button
              className="button main-action"
              // style={{opacity:0.5, cursor:"not-allowed"}}
              onClick={() => setIsGallery(true)}
            >Access Dashboard</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TruthDashboard;