// src/hooks/useAssetFilter.js
import { useState } from 'react';
import useLocalStorage from './useLocalStorage';

const useAssetFilter = () => {
  const [activeFilter, setActiveFilter] = useLocalStorage('activeFilter', 'Filter-All');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(prev => !prev);
  };

  const handleFilterClick = (filter, callback = () => {}) => {
    setActiveFilter(filter);
    callback();
};

  return {
    activeFilter,
    handleFilterClick,
    isFilterOpen,
    toggleFilter
  };
};

export default useAssetFilter;
