// userAssets.js
import React, { useState, useEffect, useMemo } from "react";
import { gsap } from "gsap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useLocalStorage from "../../hooks/useLocalStorage";
import useAssetAnimation from "../../hooks/useAssetAnimation";
import useKeyboardTriggers from "../../hooks/useKeyboardTriggers";
import useAssetFilter from "../../hooks/useAssetFilter";
import flameBig from "../../images/flame-big.gif";


function UserAssets({ tokens, opensea, data, onSelectedTokensChange, onFilteredTokensChange, clearSelectedAssets, selectedAssets, setSelectedAssets, isBurned, onBurnProcessed }) {
	// Constants
	const ASSETS_PER_CLICK = 8;

	const [assetCount, setAssetCount] = useState(0);
	const [animatedAssets, setAnimatedAssets] = useState([]);
	const [imageLoaded, setImageLoaded] = useState({});
	const [burnedTokens, setBurnedTokens] = useState(new Set());

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedTokenDetails, setSelectedTokenDetails] = useState({ index: null, token: null });

	// State: Filters and ViewMode
	const { activeFilter, handleFilterClick, isFilterOpen, toggleFilter } = useAssetFilter();
	const [viewMode, setViewMode] = useLocalStorage("viewMode", "grid");

	const isAssetSelected = (index) => selectedAssets.includes(index);
	const isAssetAnimated = (index) => animatedAssets.includes(index);
	const getAssetClasses = (index, currentClasses = []) => {
		const baseClasses = ["user-asset"];
		if (isAssetSelected(index)) baseClasses.push("selected");
		if (currentClasses.includes("animated") || isAssetAnimated(index)) baseClasses.push("animated");
		return baseClasses.join(" ");
	};

	const handleImageLoad = (tokenId) => {
		setImageLoaded(prevState => ({ ...prevState, [tokenId]: true }));
	};

	useEffect(() => {
		setAssetCount(Math.min(ASSETS_PER_CLICK, tokens.length));
	}, [tokens]);


	useEffect(() => {
		if (isBurned && selectedAssets.length > 0) {
			toggleDashboardPanel(false);
			// setBurnedTokens((prevBurnedTokens) => new Set([...prevBurnedTokens, ...selectedAssets]));
			onBurnProcessed();
			clearSelectedAssets();
		}
	}, [isBurned, selectedAssets, onBurnProcessed, clearSelectedAssets]);


	const handleAssetClick = (index) => {
		let updatedSelected;
		if (isAssetSelected(index)) {
			updatedSelected = selectedAssets.filter((item) => item !== index);
		} else {
			updatedSelected = [...selectedAssets, index];
		}
		setSelectedAssets(updatedSelected);
		onSelectedTokensChange(updatedSelected);

		if (updatedSelected.length === 0) {
			toggleDashboardPanel(false);
		} else if (selectedAssets.length === 0) {
			toggleDashboardPanel(true);
		}
	};

	const adjustGridColumn = (increase = false) => {
		const dashboardContainer = document.getElementById("Dashboard-Container");
		if (dashboardContainer) {
			const currentColumns = getComputedStyle(dashboardContainer).getPropertyValue('--grid-columns').trim();
			let newColumns = parseInt(currentColumns, 10);

			newColumns += increase ? 1 : -1;

			dashboardContainer.style.setProperty('--grid-columns', newColumns);
		}
	};

	const toggleDashboardPanel = (show) => {
		const dashboardContainer = document.getElementById("Dashboard-Container");
		if (dashboardContainer) {
			if (show) {
				dashboardContainer.classList.add("panel-active");
				// adjustGridColumn(false);
			} else {
				dashboardContainer.classList.remove("panel-active");
				// adjustGridColumn(true);
			}
		}
	};

	let allowToggle = true;

	const toggleViewFade = (newMode) => {
		if (viewMode === newMode || !allowToggle) return;

		gsap.to("#User-Assets-Array", {
			duration: 0.125,
			opacity: 0,
			y: -8,
			onComplete: () => {
				setViewMode(newMode);
				const userAssets = document.querySelectorAll(".user-asset");

				userAssets.forEach((el) => el.classList.remove("animated"));

				setTimeout(() => {
					userAssets.forEach((el) => el.classList.add("animated"));
					gsap.to("#User-Assets-Array", {
						opacity: 1,
						y: 0,
					});
				}, 500);
			},
		});
	};

	// Animations & Interactions
	useAssetAnimation(assetCount, ASSETS_PER_CLICK, setAnimatedAssets);
	useKeyboardTriggers(setViewMode, toggleViewFade);

	const toggleView = () => {
		const newMode = viewMode === "grid" ? "list" : "grid";
		toggleViewFade(newMode);
	};

	const collectionNames = {
		"0x895688bf87d73cc7da27852221799d31b027e300": {
			name: "BiG Inc",
			filter: "Filter-Big",
		},
		"0x7b390a46c868ccebf2730121faf777129b47c97c": {
			name: "BiG Inc Letters",
			filter: "Filter-Big",
		},
		"0x8c6def540b83471664edc6d5cf75883986932674": {
			name: "goblintown",
			filter: "Filter-Gobs",
		},
		"0x279c53df917579bb454b61555982dac1750187d9": {
			name: "grumpls",
			filter: "Filter-Grumpls",
		},
		"0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93": {
			name: "mcgoblin",
			filter: "Filter-Other",
		},
		"0xface84a186f597c31a6e119b4114c42f28c21c95": {
			name: "akcessores",
			filter: "Filter-Other",
		},
		"0x8cb05890b7a640341069fb65dd4e070367f4d2e6": {
			name: "Illuminati",
			filter: "Filter-Mids",
		},
		"0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9": {
			name: "Illuminati Passes",
			filter: "Filter-Other",
		},
		"0x3266b70962a61f1917f79c387749e3bd95a9ff52": {
			name: "Believer Pass",
			filter: "Filter-Other",
		},
		"0x7912a656ece2bc669d4116ad8e9495f722d92d76": {
			name: "UNDAO",
			filter: "Filter-Other",
		},
		"0x920cc6bf85c293b778fb22c79a391c8b014800dd": {
			name: "Illuminati Community",
			filter: "Filter-Other",
		},
		"0x50b4a624b297198debedf908940f7aacfba60180": {
			name: "Illuminary",
			filter: "Filter-Other",
		},
		"0x2894e6212493325da5e838450ac6b29f42a06f46": {
			name: "goblinaries",
			filter: "Filter-Other",
		},
		"0x02dba5957bd6c872be6a96d2d7ea80c773cf7784": {
			name: "PeePee Goblins",
			filter: "Filter-Other",
		},
		"0x9777f2c85713f05356da1b43bce02b30bda1bf69": {
			name: "Summer Solstice 2022",
			filter: "Filter-Other",
		},
		"0xe25f0fe686477f9df3c2876c4902d3b85f75f33a": {
			name: "DAO",
			filter: "Filter-Other",
		},
		"0x9ef8750c72061edbeeef4beb1aceee5b5a63748a": {
			name: "The 187",
			filter: "Filter-Mids",
		},
		// Non Truth Projects
		"0x67df41edf21e1c581a77e939f60db7baa5e96993": {
			name: "Kryptic Kids",
			filter: "Filter-Other",
		},
		"0x66dab8a88b7ca020a89f45380cc61692fe62e7ed": {
			name: "Cryptoface",
			filter: "Filter-Other",
		},
		"0x412b986a5a8e2e50bdafe7595c1f066c654ab1dc": {
			name: "Test 721 Collection",
			filter: "Filter-Mids",
		},
		"0xf062dbe7eba009dc9521ec9c2d97445c1160e763": {
			name: "Test 1155 Collection",
			filter: "Filter-Other",
		},
		"0x7fbb00d744b5975e870191924076af620039b169": {
			name: "Another Test 721 Collection",
			filter: "Filter-Mids",
		},
		"0xd4d1b6a29321754a8752def9cda6735fbfb8c29f": {
			name: "Another Test 1155 Collection",
			filter: "Filter-Other",
		},
	};

	const isOneOfOneToken = (contract, tokenId) => {
		const normalizedContract = contract.toLowerCase();
		const oneOfOneTokensByContract = {
			"0x8cb05890b7a640341069fb65dd4e070367f4d2e6": new Set([332, 6513, 1953, 4151, 4510, 6638]),
			"0x8c6def540b83471664edc6d5cf75883986932674": new Set([4072, 1474, 3241, 4587, 4893, 5742, 5948, 6485, 6749, 7944, 899, 8995]),
			"0x895688bf87d73cc7da27852221799d31b027e300": new Set([4837, 6278, 531, 1902, 6828, 3897, 2269, 3143, 714, 310, 319, 3503, 5079]),
			"0x279c53df917579bb454b61555982dac1750187d9": new Set([352, 355, 541, 573, 1035, 2293, 2343, 2344, 2519, 2780, 2815, 3527, 33, 98, 127, 637, 1005, 1380, 1849, 1887, 1889, 1900, 2111, 2210, 2230, 2408, 2717, 2887, 2918, 3048, 3162, 3182, 3571, 3697, 3710, 2254, 2838, 2936, 3004, 3006, 3007, 2405, 9, 28, 908, 962, 1143, 1675, 1947, 2692, 2123, 2135, 2621, 3129, 3210, 83, 1098, 1770, 1893, 2841]),
			"0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93": new Set([2801, 1076, 7044, 915]),
		};

		const oneOfOneTokens = oneOfOneTokensByContract[normalizedContract];
		return oneOfOneTokens ? oneOfOneTokens.has(tokenId) : false;
	};

	const getCollectionName = (contract) => {
		const normalizedContract = contract.toLowerCase();
		return collectionNames[normalizedContract]?.name || "Unknown Collection";
	};

	const getCollectionFilter = (contract) => {
		const normalizedContract = contract.toLowerCase();
		return collectionNames[normalizedContract]?.filter || "Filter-Other";
	};

	const loadMoreTokens = () => {
		setAssetCount((prevCount) => {
			const newCount = Math.min(prevCount + ASSETS_PER_CLICK, filteredTokens.length);
			return newCount;
		});
	};

	// tippy
	const TooltipContent = ({ title, value }) => (
		<span className="tip-content">
			{title} <span className="tip-callout">{value}</span>
		</span>
	);
	const defaultTippyProps = {
		delay: 400,
		theme: "custom",
		animation: "fadeInUp",
	};

	const filteredTokens = useMemo(() => {
		return activeFilter === "Filter-All"
			? tokens
			: tokens.filter((token) => getCollectionFilter(token.contract) === activeFilter);
	}, [tokens, activeFilter, getCollectionFilter]);

	useEffect(() => {
		onFilteredTokensChange(filteredTokens);
	}, [filteredTokens]);

	return (
		<div id="User-Assets">
			{/* TODO: Add no items / no profile screens */}
			{/* TODO: Add points / equity breakdown */}
			<div id="User-Assets-Controls">
				<nav id="Asset-Filter" className={isFilterOpen ? "open" : ""}>
					<button id="Filter-All" className={`filter-option ${activeFilter === "Filter-All" ? "active" : ""}`} onClick={() => handleFilterClick("Filter-All", clearSelectedAssets)}>
						<i className="filter-option-icon">
							<LazyLoadImage src="https://i.seadn.io/gae/LTKz3om2eCQfn3M6PkqEmY7KhLtdMCOm0QVch2318KJq7-KyToCH7NBTMo4UuJ0AZI-oaBh1HcgrAEIEWYbXY3uMcYpuGXunaXEh" />
						</i>
						<span className="filter-option-text">All</span>
					</button>
					<button id="Filter-Mids" className={`filter-option ${activeFilter === "Filter-Mids" ? "active" : ""}`} onClick={() => handleFilterClick("Filter-Mids", clearSelectedAssets)}>
						<i className="filter-option-icon">
							<LazyLoadImage src="https://i.seadn.io/gae/V7sJyL6PYZKLlJlb7QG8oZcFvCzBYm2BqUiSoIL8Z2miupOPR5VdAMQqkf3Nq-lf763efqzxu5QiItQmASQrJrSUqOWU89Z7LJvW" />
						</i>
						<span className="filter-option-text">Illuminati</span>
					</button>
					<button id="Filter-Gobs" className={`filter-option ${activeFilter === "Filter-Gobs" ? "active" : ""}`} onClick={() => handleFilterClick("Filter-Gobs", clearSelectedAssets)}>
						<i className="filter-option-icon">
							<LazyLoadImage src="https://i.seadn.io/gae/cb_wdEAmvry_noTfeuQzhqKpghhZWQ_sEhuGS9swM03UM8QMEVJrndu0ZRdLFgGVqEPeCUzOHGTUllxug9U3xdvt0bES6VFdkRCKPqg" />
						</i>
						<span className="filter-option-text">Goblintown</span>
					</button>
					<button
						id="Filter-Grumpls"
						className={`filter-option ${activeFilter === "Filter-Grumpls" ? "active" : ""}`}
						onClick={() => handleFilterClick("Filter-Grumpls", clearSelectedAssets)}
					>
						<i className="filter-option-icon">
							<LazyLoadImage src="https://i.seadn.io/gcs/files/28d196256e86372a5ded002691d87a09.png" />
						</i>
						<span className="filter-option-text">Grumpls</span>
					</button>
					<button id="Filter-Big" className={`filter-option ${activeFilter === "Filter-Big" ? "active" : ""}`} onClick={() => handleFilterClick("Filter-Big", clearSelectedAssets)}>
						<i className="filter-option-icon">
							<LazyLoadImage src="https://i.seadn.io/gcs/files/861db1ad64c2f17a14cff54f3f1f4e5a.png" />
						</i>
						<span className="filter-option-text">BiG iNC</span>
					</button>
					<button id="Filter-Other" className={`filter-option ${activeFilter === "Filter-Other" ? "active" : ""}`} onClick={() => handleFilterClick("Filter-Other", clearSelectedAssets)}>
						<i className="filter-option-icon">
							<LazyLoadImage src="https://i.seadn.io/gcs/files/567d3b0f641c01048459cbd588f217e5.png" />
						</i>
						<span className="filter-option-text">Other</span>
					</button>
					<button id="Asset-Filter-Toggle" onClick={toggleFilter} className={isFilterOpen ? "active" : ""}>
						<i className="fa fa-chevron-down"></i>
					</button>
				</nav>
				<div id="Assets-View-Toggle" onClick={toggleView} className={viewMode}>
					<Tippy
						{...defaultTippyProps}
						content={
							<span className="tip-content">
								Grid View <span className="tip-callout">G</span>
							</span>
						}
					>
						<button className={viewMode === "grid" ? "active" : ""}>
							<i className="fa fa-grid-2"></i>
						</button>
					</Tippy>

					<Tippy
						{...defaultTippyProps}
						content={
							<span className="tip-content">
								List View <span className="tip-callout">L</span>
							</span>
						}
					>
						<button className={viewMode === "list" ? "active" : ""}>
							<i className="fa fa-list"></i>
						</button>
					</Tippy>
				</div>
				{/* <button id="Details-Toggle" className="button">
					<i className="fas fa-circle-info"></i>
				</button> */}
			</div>
			{/* TODO: Style sorting */}

			<div id="User-Assets-Array" className={viewMode}>
				{filteredTokens.length === 0 ? (
					<div>No tokens match criteria</div>
				) : (
					<>
						{filteredTokens.slice(0, assetCount).map((tokens, i) => (
							// Render each asset using the token number from the state

							<div key={i} className={`user-asset ${isAssetSelected(i) ? "selected" : ""} ${(burnedTokens.has(i) || tokens.escrow) ? "burned" : ""}`} data-token-number={tokens.id} onClick={() => !(burnedTokens.has(i) || tokens.escrow) && handleAssetClick(i)}>
								{!(burnedTokens.has(i) || tokens.escrow) &&
									<button className={`checkbox ${selectedAssets.includes(i) ? "selected" : ""}`}>
										<span></span>
									</button>
								}
								<Tippy
									{...defaultTippyProps}
									content={
										<TooltipContent
											title="Equity Value"
											value={
												tokens.inSnapshot ?
													(isOneOfOneToken(tokens.contract, tokens.id) ?
														"∞" :
														(tokens.price === 0 ? "N/A" : `$${tokens.price.toFixed(2)}`)
													) :
													"N/A"
											}
										/>
									}
								>
									<div className="token">
										<img
											alt={tokens.id}
											src={tokens.image}
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = tokens.image.includes('ipfs.filebase.io')
													? tokens.image.replace('ipfs.filebase.io', 'ipfs.io')
													: tokens.image.includes('ipfs.io')
														? tokens.image.replace('ipfs.io', 'ipfs.filebase.io')
														: tokens.image;
											}}
											onLoad={() => handleImageLoad(tokens.id)}
											className={`fade-in ${imageLoaded[tokens.id] ? "visible" : ""}`}
										/>
										{
											(burnedTokens.has(i) || tokens.escrow) ? (
												<>
													<span className="burned-icon" role="img" aria-label="burned">
														<div className="flame">
															<img src={flameBig} alt="flame"></img>
														</div>
													</span>
													{tokens.type === 1155 && (
														<span className="burned-token-num">{tokens.amount}</span>
													)}
												</>
											) : null
										}
									</div>

								</Tippy>
								<div className="asset-info">
									<div className="asset-collection">{getCollectionName(tokens.contract)}</div>
									<div className="asset-name">
										{tokens.type == 1155 && (
											<span className="token-total">{tokens.amount}x&nbsp;</span>
										)}
										{getCollectionName(tokens.contract)} #{tokens.id}
									</div>
								</div>
								<ul className="asset-stats">

									<li className="value asset-burn-value">
										<i className="far fa-fire"></i>
										<span className="price-stat asset-stat">
											{tokens.inSnapshot ?
												(isOneOfOneToken(tokens.contract, tokens.id) ?
													"∞" :
													(tokens.price === 0 ? "N/A" : `$${tokens.price.toFixed(2)}`)
												) :
												"N/A"
											}
										</span>
									</li>

									{/* <li className="value asset-raffle-value">
										<i className="far fa-ticket"></i>
										<span className="raffle-stat asset-stat ">{tokens.raffleScore}</span>
									</li> */}

									<li className="value asset-visibility">
										<i className="far fa-eye"></i>
										<span className="asset-stat">{tokens.isListed ? "Listed" : "Unlisted"}</span>
									</li>
								</ul>
							</div>

						))}
						{assetCount < filteredTokens.length && (
							<button id="Load-More-Tokens" className="button" onClick={loadMoreTokens}>
								Load More
							</button>
						)}
					</>
				)}
			</div>
		</div >
	);
}

export default UserAssets;
