// components/dashboard.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import UserHero from "../userHero";
import UserAssets from "../userAssets";
import BurnInfo from "../userProgress";
import DashboardDetails from "../dashboardDetails";
import Footer from "../footer";
import { useAuth0 } from "@auth0/auth0-react";

function Dashboard({ tokens, opensea, data, onLogout, isEscrowing, onEscrowReset, isBurned, resetBurnState, isBurnedValue, tokensAggregated }) {
	const [selectedTokens, setSelectedTokens] = useState([]);
	const [activeTokens, setActiveTokens] = useState(tokens);
	
	const [selectedAssets, setSelectedAssets] = useState([]);

	const [randomPfp, setRandomPfp] = useState(null);


	const handleFilteredTokensChange = useCallback((newTokens) => {
        if (JSON.stringify(newTokens) !== JSON.stringify(activeTokens)) {
            setActiveTokens(newTokens);
        }
    }, [activeTokens]);

	const handleSelectedTokensChange = (selectedIndices) => {
		let tokens = selectedIndices.map((index) => activeTokens[index]);
		setSelectedTokens(tokens);
	};

	useEffect(() => {
		if (tokensAggregated && tokensAggregated.length > 0) {
			const randomIndex = Math.floor(Math.random() * tokensAggregated.length);
			setRandomPfp(tokensAggregated[randomIndex].image);
		}
	}, [tokensAggregated]);

	const clearSelectedAssets = () => {
        setSelectedTokens([])
		setSelectedAssets([]);
		handleSelectedTokensChange([]);
		toggleDashboardPanel(false);
    };

	const toggleDashboardPanel = (show) => {
		const dashboardContainer = document.getElementById("Dashboard-Container");
		if (dashboardContainer) {
			if (show) {
				dashboardContainer.classList.add("panel-active");
			} else {
				dashboardContainer.classList.remove("panel-active");
			}
		}
	};


	return (
		<div id="Dashboard-Container" className="site-container">
			<main id="Dashboard">
				<Header tokens={selectedTokens} opensea={opensea} data={data} profilePicture={randomPfp} onLogout={onLogout} />
				{/* <UserHero tokens={tokens} opensea={opensea} data={data} profilePicture={randomPfp} /> */}
				<BurnInfo tokens={tokens} opensea={opensea} data={data} totalBurnValue={isBurnedValue} tokensAggregated={tokensAggregated} />
				<UserAssets tokens={tokens} opensea={opensea} data={data} onSelectedTokensChange={handleSelectedTokensChange} onFilteredTokensChange={handleFilteredTokensChange} clearSelectedAssets={clearSelectedAssets}  selectedAssets={selectedAssets}   setSelectedAssets={setSelectedAssets} isBurned={isBurned} onBurnProcessed={resetBurnState} />
				<Footer />
			</main>
			<DashboardDetails tokens={selectedTokens} opensea={opensea} data={data} onSelectedTokensChange={handleSelectedTokensChange} clearSelectedAssets={clearSelectedAssets} isEscrowing={isEscrowing} onEscrowReset={onEscrowReset} />
		</div>
	);
};

export default Dashboard;
