// components/modals/modal.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useModal } from '../../contexts/ModalContext';
import logoWhite from "../../images/logo-white.gif";
import flameBig from "../../images/flame-big.gif";
import wiggle from "../../images/wiggle-line.png";

const Modal = ({ id, type, steps, showHeader, showProgressIndicator, children, showCloseButton }) => {

  const { modals, closeModal } = useModal();
  const totalSteps = steps ? steps.length : 0;
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    if (!modals[id].visible) {
      setCurrentStep(1);
    }
  }, [modals[id].visible]);

  const goNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };


  const NavigationHeader = ({ currentStep, totalSteps, goBack, goNext }) => {
    // Determine if navigation arrows should be disabled
    const isDisabledBack = currentStep <= 1;
    const isDisabledNext = currentStep >= totalSteps;

    return (
      <>
        {/* <div className="token-burn-label">
          <div className="flame">
            <img src={flameBig} alt="flame"></img>
          </div>
          <h1>How it Works</h1>
        </div> */}
        <div className="modal-header">
          {/* <div className="logo">
          <i className="logo-mark">
            <img src={logoWhite} alt="Truth Arts Logo"></img>
          </i>
          <span className="logo-type">Truth Arts</span>
        </div> */}
          <div className="wiggle-divide">
            <img src={wiggle} alt="wiggle"></img>
          </div>
          <div className="modal-navigation">
            <button className={`icon-button ${isDisabledBack ? 'disabled' : ''}`} onClick={() => !isDisabledBack && goBack()}>
              <i
                className={`fa fa-arrow-left`}

              ></i>
            </button>
            <button className={`icon-button ${isDisabledNext ? 'disabled' : ''}`} onClick={() => !isDisabledNext && goNext()}>
              <i
                className={`fa fa-arrow-right`}
              ></i>
            </button>
          </div>

        </div>
      </>
    );
  };
  const ProgressIndicator = ({ currentStep, totalSteps }) => {
    return (
      <div className="modal-progress">
        {Array.from({ length: totalSteps }, (_, index) => (
          <React.Fragment key={index}>
            <div className={`step-circle ${index < currentStep ? 'current' : ''}`}>
              <div className="step-number">
                {index + 1}
              </div>
            </div>
            {index < totalSteps - 1 && (
              <div className={`connector ${index < currentStep - 1 ? 'current' : ''}`}></div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const ConditionalHeader = () => (
    showHeader && (
      <NavigationHeader
        currentStep={currentStep}
        totalSteps={totalSteps}
        goBack={goBack}
        goNext={goNext}
      />
    )
  );
  const ConditionalProgressIndicator = () => (
    showProgressIndicator && (
      <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />
    )
  );

  const isOpen = modals[id];
  const isOverlay = type === 'overlay';
  const modalClass = isOverlay ? 'overlay' : 'pane';
  const modalRoot = document.getElementById('Modals');
  const modalInfo = modals[id];

  if (!modalInfo || !modalInfo.visible) return null;


  return isOpen && ReactDOM.createPortal(
    <div id={id} className={`modal ${modalClass}`}>
      {/* <div className="flame">
        <img src={flameBig} alt="flame"></img>
      </div> */}
      <div className={`modal-content`}>
        <ConditionalHeader />
        <ConditionalProgressIndicator />
        {totalSteps > 0 && steps[currentStep - 1]}
      </div>
      {(modalInfo.showCloseButton || showCloseButton) && (
        <button onClick={() => { closeModal(id) }} className="modal-close icon-button">
          <i className="fa-solid fa-xmark"></i>
        </button>
      )}
    </div>,
    modalRoot
  );
};

export default Modal;