import React, {useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../images/logo-white.gif";

const Landing = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  });

  return (
    <div className="login">
        <div>
          <img src={logo} alt="Logo" className="login-image"></img>
        </div>
      </div>
  );
};

export default Landing;
