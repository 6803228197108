import React from "react";
import wiggle from "../../images/wiggle-line.png";
import logo from "../../images/logo-white.gif";


function Footer() {
	return (
		<>
			<footer id="foot">
				<div id="contact" className="wigglySides hasToggle ">
					<h3 className="screamer target target--brand"><a href="mailto:help@trutharts.com">help@trutharts.com</a></h3>
					<i className="toggle"></i>
				</div>
				<div id="footContent">
					<div id="footAbout">
						<div id="footLogo" className="target target--brand">
							<img className="logoMark" src={logo} alt="Truth Arts Logo"></img>
						</div>
						<p>Truth Arts is a collective of <strong>high-powered creative mutant world-builders</strong>, here to tell stories so <strong>wild</strong>, <strong>fantastical</strong>, <strong>heart-wrenching</strong>, <strong>touching</strong>, and <strong>epically ridiculous</strong> that they can't not be believed.</p>
					</div>
					<div id="footSocial" className="edge">
						<h3>Truth Social</h3>
						<a id="xLink" href="https://twitter.com/truth" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="Follow Truth Arts on X">
							<i className='socialIcon'></i>
							<h5>Follow on X</h5>
						</a>
						<a id="discordLink" href="https://discord.gg/illuminati" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="Join the Truth Arts Discord">
							<i className='socialIcon'></i>
							<h5>Join the Discord</h5>
						</a>
						<a id="republicLink" href="https://republic.com/truth-arts" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="View the Truth Arts Deal Page on Republic">
							<i className='socialIcon'></i>
							<h5>Visit Republic</h5>
						</a>
					</div>
				</div>
				<div className="wiggle-divide">
					<img src={wiggle} alt="wiggle"></img>
				</div>
				<div id="footFoot">
					<div id="colo">&copy;2024 Truth Arts Inc. All Rights Reserved.</div>
					<div id="legal">
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Privacy+Policy_2.21.24.pdf" className="target target--brand" target="_blank" rel="noreferrer" title="Read the Truth Arts Privacy Policy">Privacy Policy</a>
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Terms+and+Conditions.pdf" className="target target--brand" target="_blank" rel="noreferrer" title="Read the Truth Arts Terms and Conditions">Terms and Conditions</a>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
