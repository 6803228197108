import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoWhite from "../../images/logo-white.gif";
import { useModal } from "../../contexts/ModalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import userPfp from "../../images/user-pfp.png";
import ThemeSwitcher from "../../components/ThemeSwitcher";
import { useAuth0 } from "@auth0/auth0-react";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import wiggle from "../../images/wiggle-line.png";
import republic from "../../images/republic.png";

import {
	ConnectWallet,
	useAddress,
	useUser,
	useDisconnect
} from "@thirdweb-dev/react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Header({ tokens, opensea, data, profilePicture, onLogout }) {
	const navigate = useNavigate();
	const { openModal } = useModal();
	const disconnect = useDisconnect();

	const address = useAddress();
	const { isLoggedIn } = useUser();

	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [jwt, setJWT] = useState('');
	const [saw, setSaw] = useState(false);

	const [connectedWallets, setConnectWallets] = useState(data?.data?.user_metadata?.wallets || []);
	const [walletsMapping, setWalletsMapping] = useState({});

	let minimumLoginCountHowItWorks = 1;

	const fetchApi = async () => {
		try {
			const token = await getAccessTokenSilently();
			setJWT(token);
			return token;
		} catch (error) {
			console.error("Failed to retrieve access token", error);
		}
	};

	const getUser = async () => {
		try {
			const token = await fetchApi();

			let config = {
				method: 'post',
				url: 'https://burnitall.biginc.business/setwallet',
				withCredentials: true,
				data: {
					token: token,
				},
			};

			const response1 = await axios.request(config);

			if (response1?.data?.data?.logins_count <= minimumLoginCountHowItWorks) {
				openModal("HowItWorks", true)
			}

			if (response1?.data?.data?.user_metadata?.wallets) {
				let newWalletsMapping = {};
				const formattedWallets = response1.data.data.user_metadata.wallets.map(wallet => {
					const formattedWallet = wallet && wallet.length > 8 ? wallet.substring(0, 4) + '...' + wallet.substring(wallet.length - 4) : wallet;
					newWalletsMapping[formattedWallet] = wallet;
					return formattedWallet;
				});
				setConnectWallets(formattedWallets);
				setWalletsMapping(newWalletsMapping);

				let wallet = address.substring(0, 4) + '...' + address.substring(address.length - 4);

				toast.info(`Connected to ${wallet}`);
			}

		} catch (error) {
			disconnect();
		}
	}

	useEffect(() => {
		if (address && user && isLoggedIn && isAuthenticated) {
			getUser();
		}
	}, [address, isLoggedIn, user, isAuthenticated]);

	const removeWallet = async (formattedWalletToRemove) => {
		if (window.confirm('Are you sure you want to remove this wallet?')) {
			try {
				const fullWalletToRemove = walletsMapping[formattedWalletToRemove];

				let config = {
					method: 'post',
					url: 'https://burnitall.biginc.business/removewallet',
					data: {
						token: jwt,
						wallet: fullWalletToRemove,
					},
				};

				await axios.request(config);
				setConnectWallets(connectedWallets.filter(wallet => wallet !== formattedWalletToRemove));
				toast.success("Wallet removed successfully");
			} catch (error) {
				console.error("Error removing wallet:", error);
				toast.error("Error. Please try again later.");
			}
		}
	};

	const goHome = () => {
		window.location.href = "https://www.app.trutharts.com"
	}

	return (
		<>
			<header id="Header">
				<a id="Header-Logo" className="logo" onClick={goHome}>
					<i className="logo-mark">
						<img src={logoWhite} alt="Truth Arts Logo"></img>
					</i>
					<span className="logo-type">Truth Arts</span>
				</a>

				<div className="button-group">

					<ConnectWallet
						className="connect-wallet"
						id="Connect-Wallet"
						btnTitle={"Connect Wallet"}
						modalTitleIconUrl={""}
						showThirdwebBranding={false}
						modalTitle={""}
						switchToActiveChain={true}
						modalSize={"compact"}
						welcomeScreen={{ title: "" }}
						auth={{
							loginOptional: false,
						}}
					/>
					{/* <button id="How-Button" className="button disabled">
						<i className="fas fa-exclamation"></i><span className="extra">BURN LIMIT REACHED</span>
					</button> */}

					{/* <button id="How-Button" className="button" onClick={() => openModal("HowItWorks", true)}>
						<i className="fas fa-question"></i><span className="extra">How it Works</span>
					</button> */}
					
					{/* <button id="How-Button" className="button" onClick={() => openModal("WalletConfig", true)}>
					<i className="fa fa-wallet"></i>
					</button> */}

					<button className="button square-button" onClick={onLogout}><i className="fas fa-arrow-right-from-bracket"></i></button>
				</div>
			</header>
			<div className="wiggle-divide">
				<img src={wiggle} alt="wiggle"></img>
			</div>
		</>
	);
}

export default Header;
