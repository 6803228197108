// contexts/ModalContext
import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState(() => ({
    ProfileCustomization: { visible: false, showCloseButton: true },
    WalletConfig: { visible: false, showCloseButton: true },
    HowItWorks: { visible: false, showCloseButton: true },
    Republic: { visible: false, showCloseButton: true },
    KYC: { visible: false, showCloseButton: true },
    Burn: { visible: false, showCloseButton: true },
    // ... other modals
  }));

  const openModal = (id, showCloseButton) => {
    setModals((prevModals) => ({
      ...prevModals,
      [id]: { visible: true, showCloseButton: showCloseButton }
    }));
  };
  
  const closeModal = (id) => {
    setModals((prevModals) => ({
      ...prevModals,
      [id]: { ...prevModals[id], visible: false }
    }));
  };
  

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
