// useLocalStorage.js
import { useState } from 'react';

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // Detect private browsing mode
      if (isPrivateMode(error)) {
        // Trigger a non-blocking notification to the user
        notifyUserOfPrivateMode();
      } else {
        console.warn("Error accessing local storage.", error);
      }
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.warn("Error setting local storage.", error);
    }
  };

  return [storedValue, setValue];
}

function isPrivateMode(error) {
  // Check the specific error message or code to detect private browsing
  // This can vary between browsers and their versions
  return error && (error.code === 22 || error.code === 1014 || error.name === 'QuotaExceededError');
}

function notifyUserOfPrivateMode() {
  // Implement a notification system or use an existing one to inform the user
  // Example:
  // showToastNotification('You are in private browsing mode. Some features may be limited.');
}

export default useLocalStorage;
