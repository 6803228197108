// hooks/useAssetAnimation.js
import { useEffect } from "react";
import { gsap } from "gsap";

function useAssetAnimation(assetCount, assetsPerClick, setAnimatedAssets) {

  useEffect(() => {
    const newAnimatedAssets = [];
    for(let i = assetCount - assetsPerClick; i < assetCount; i++) {
      newAnimatedAssets.push(i);
    }
    setAnimatedAssets(prev => [...prev, ...newAnimatedAssets]);
  }, [assetCount, assetsPerClick, setAnimatedAssets]);

  const ITEM_DELAY = 0.25;

useEffect(() => {
  const assets = Array.from(document.querySelectorAll(".user-asset:not(.animated)")).slice(-assetsPerClick);

  let currentDelay = 0;
  assets.forEach((asset) => {
    gsap.set(asset, {
      opacity: 0,
      // y: '25%',
      // scale: 0.95,
    });

    gsap.to(asset, {
      delay: currentDelay,
      duration: 0.25,
      ease: "ease",
      opacity: 1,
      // scale: 1,
      // y: '0%',
      onComplete: () => {
        setAnimatedAssets(prev => [...prev, asset]);
      }
    });

    currentDelay += ITEM_DELAY;
  });
}, [assetCount, assetsPerClick, setAnimatedAssets]);
}

export default useAssetAnimation;



