// contexts/BurnContext.js
import React, { createContext, useContext, useState } from 'react';

const BurnableTokensContext = createContext();

export const useBurnableTokens = () => useContext(BurnableTokensContext);

export const BurnContextProvider = ({ children }) => {
  const [totalBurnableTokens, setTotalBurnableTokens] = useState(0);
  const [totalBurnableTokensValue, setTotalBurnableTokensValue] = useState(0);
  const [burnableTokens, setBurnableTokens] = useState([]);
  const [burnableParams, setBurnableParams] = useState([]);

  const updateTotalBurnableTokens = (tokens, value, burns, params) => {
    setTotalBurnableTokens(tokens);
    setTotalBurnableTokensValue(value);
    setBurnableTokens(burns);
    setBurnableParams(params);
  };

  return (
    <BurnableTokensContext.Provider value={{ totalBurnableTokens, totalBurnableTokensValue, burnableTokens, burnableParams, updateTotalBurnableTokens }}>
      {children}
    </BurnableTokensContext.Provider>
  );
};