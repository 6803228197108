import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './utils/router'
import reportWebVitals from './utils/vitals';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./auth/config";
import { ModalProvider } from './contexts/ModalContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { BurnContextProvider } from './contexts/BurnContext';
import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
  trustWallet,
  phantomWallet
} from "@thirdweb-dev/react";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/dashboard`,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    {...providerConfig}
  >
    <ThirdwebProvider
      activeChain="ethereum"
      clientId="e8eb5149cc4a9defeb07ccc908376ee4"
      supportedWallets={[
        metamaskWallet({ recommended: true }),
        coinbaseWallet(),
        phantomWallet(),
        walletConnect(),
        safeWallet({
          personalWallets: [
            metamaskWallet(),
            coinbaseWallet()
          ],
        }),
        trustWallet(),
      ]}
      sdkOptions={{
        gatewayUrls: ["https://truth.myfilebase.com/ipfs/"],
      }}
      authConfig={{
        domain: "",
        authUrl: "https://burnitall.biginc.business/auth",
      }}
    >
      <ThemeProvider>
        <ModalProvider>
          <BurnContextProvider>
            <AppRouter />
          </BurnContextProvider>
        </ModalProvider>
      </ThemeProvider>
    </ThirdwebProvider>
  </Auth0Provider>,

);

reportWebVitals();
