// hooks/useKeyboardTriggers.js
import { useEffect } from "react";

function useKeyboardTriggers(setViewMode, toggleViewFade) {
    useEffect(() => {
        const dashboardContainer = document.getElementById("Dashboard-Container");

        const actions = {
			// I: () => {
			//     if (dashboardContainer) {
			//         dashboardContainer.classList.toggle("panel-active");
			//     }
			// },
			L: () => toggleViewFade("list"),
			G: () => toggleViewFade("grid"),
		};

        const handleKeyDown = (event) => {
            if (event.target.tagName === "INPUT") return;

            const action = actions[event.key.toUpperCase()];
            if (action) {
                action();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setViewMode, toggleViewFade]);
}

export default useKeyboardTriggers;
