import React, { useState, useEffect } from "react";
import { useBurnableTokens } from '../../contexts/BurnContext';
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function UserProgress({ totalBurnValue, tokensAggregated }) {

  const { totalBurnableTokensValue } = useBurnableTokens();
  const [totalBurnableValue, setTotalBurnableValue] = useState(0);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total = tokensAggregated.reduce((sum, token) => {
      return sum + (token.escrow ? token.amount * token.price : 0);
    }, 0);
    setTotal(total + totalBurnValue + totalBurnableTokensValue);
    setTotalBurnableValue(total + totalBurnValue + totalBurnableTokensValue);
  }, [tokensAggregated, totalBurnValue, totalBurnableTokensValue]);

  const buckets = [100, 1000, 2500, 5000, 10000, 25000, 50000];

  const getCurrentBucket = () => {
    let currentBucket = buckets[0];
    for (let i = 0; i < buckets.length - 1; i++) {
      if (totalBurnableValue >= buckets[i] && totalBurnableValue < buckets[i + 1]) {
        currentBucket = buckets[i + 1];
        break;
      }
    }
    if (totalBurnableValue >= buckets[buckets.length - 1]) {
      currentBucket = buckets[buckets.length - 1];
    }
    return currentBucket;
  };

  const calculateProgressWidth = () => {
    const currentBucket = getCurrentBucket();
    const previousBucket = currentBucket === buckets[0] ? 0 : buckets[buckets.indexOf(currentBucket) - 1];

    if (totalBurnableValue >= currentBucket) {
      return 100;
    } else {
      const rawWidth = ((totalBurnableValue - previousBucket) / (currentBucket - previousBucket)) * 100;
      return Math.round(rawWidth);
    }
  };

  const progressWidth = calculateProgressWidth();

  // tippy
  const TooltipContent = ({ title, value }) => (
    <span className="tip-content">
      {title} <span className="tip-callout">{value}</span>
    </span>
  );
  const defaultTippyProps = {
    delay: 400,
    theme: "custom",
    animation: "fadeInUp",
  };

  return (

    <div id="Burn-Progress-Bar">
      <div id="Burn-Explainer">

        <h2>Equity <br></br>Meter</h2>
        <div className="copy">
          <p className="large"><strong>The burn has reached its limit. Your equity meter below reflects your possible future equity rights in Truth Arts. Be sure to complete KYC with <a href="https://republic.com/truth-arts" target="_blank" className="a-tag">Republic</a>.</strong></p>
          <p>The sum of your NFTs equate to an equity tier. For example, if the value of your NFTs is $1,500, then you fall between the $1,000 and $2,500 tiers and will receive $1,000 of future equity, <strong>not $2,500</strong>. Read more on our <a href="https://blog.trutharts.com/operation-resurrection-faqs" target="_blank" className="a-tag">FAQ</a> and our deal page on <a href="https://republic.com/truth-arts" target="_blank" className="a-tag">Republic</a>.</p>
        </div>
      </div>
      <Tippy
        {...defaultTippyProps}
        content={
          <TooltipContent
            title="Total Equity Value"
            value={`$${total.toFixed(2).toLocaleString()}`}
          />
        }
      >
        <div id="Burn-Progress-Buckets" className="buckets">
          {buckets.map((bucket) => {
            const isActive = totalBurnableValue >= bucket;
            const isCurrentBucket = bucket === getCurrentBucket();
            const bucketStyle = isCurrentBucket ? { '--progress-width': `${progressWidth}%` } : {};

            return (
              <div
                key={bucket}
                id={`Bucket-${bucket}`}
                className={`bucket ${isActive ? 'activated' : ''}`}
                style={bucketStyle}
              >
                <div className="label">${bucket.toLocaleString()}</div>
              </div>
            );
          })}
        </div>
      </Tippy>
    </div>

  );
}

export default UserProgress;
