// components/presentation.js
import React, { useState, useEffect } from 'react';
import Modal from '../modals/modal';
import { LazyLoadImage } from "react-lazy-load-image-component";
import userBanner from "../../images/user-banner.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import { useModal } from '../../contexts/ModalContext';
import axios from "axios";
import {
	useAddress,
	useUser,
	ConnectWallet,
	useChainId,
	useSigner,
	useDisconnect
} from "@thirdweb-dev/react"
import { useBurnableTokens } from '../../contexts/BurnContext';
import flameBig from "../../images/flame-big-grey.gif";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ethers } from "ethers";
import wiggle from "../../images/wiggle-line.png";
import burnHero from "../../images/burn-hero.png";
import equityMeter from "../../images/equity-meter.png";
import exampleTokens from "../../images/example-tokens.png";
import selectedTokens from "../../images/selected-tokens.png";


function Presentation({ tokensAggregated, opensea, data, discordUser, onConnectDiscord, onMetadataUpdated, onEscrow, onBurnComplete }) {

	const address = useAddress();
	const { totalBurnableTokens, totalBurnableTokensValue, burnableTokens, burnableParams } = useBurnableTokens();
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [jwt, setJWT] = useState('');
	const [isTxProcessingModal, setIsTxProcessingModal] = useState(false);
	const disconnect = useDisconnect();

	const [connectedWallets, setConnectWallets] = useState(data?.data?.user_metadata?.wallets || []);
	const [walletsMapping, setWalletsMapping] = useState({});

	const fetchApi = async () => {
		try {
			const token = await getAccessTokenSilently();
			setJWT(token);
			return token;
		} catch (error) {
			console.error("Failed to retrieve access token", error);
		}
	};

	useEffect(() => {
		if (address && user && isAuthenticated) {
			fetchApi();
		}
	}, [address, user, isAuthenticated]);

	const removeWallet = async (formattedWalletToRemove) => {
		if (window.confirm('Are you sure you want to remove this wallet?')) {
			try {
				const fullWalletToRemove = walletsMapping[formattedWalletToRemove];

				let config = {
					method: 'post',
					url: 'https://burnitall.biginc.business/removewallet',
					data: {
						token: jwt,
						wallet: fullWalletToRemove,
					},
				};

				await axios.request(config);
				setConnectWallets(connectedWallets.filter(wallet => wallet !== formattedWalletToRemove));
				toast.success("Wallet removed successfully");
			} catch (error) {
				console.error("Error removing wallet:", error);
				toast.error("Error. Please try again later.");
			}
		}
	};

	const chooseWallet = async (formattedWalletToRemove) => {
		if (window.confirm('Are you sure you want to choose this wallet?')) {
			try {
				const fullWalletToRemove = walletsMapping[formattedWalletToRemove];

				// let config = {
				// 	method: 'post',
				// 	url: 'https://burnitall.biginc.business/removewallet',
				// 	data: {
				// 		token: jwt,
				// 		wallet: fullWalletToRemove,
				// 	},
				// };

				// await axios.request(config);
				// setConnectWallets(connectedWallets.filter(wallet => wallet !== formattedWalletToRemove));
				toast.success("Success");
			} catch (error) {
				console.error("Error choosing wallet:", error);
				toast.error("Error. Please try again later.");
			}
		}
	};


	return (
		<div id="Presentation">
			<div id="Modals">
				<Modal
					id="ProfileCustomization"
					type="overlay"
					steps={[
						<StepOne data={data} jwt={jwt} onMetadataUpdated={onMetadataUpdated} />,
						<StepTwo data={data} jwt={jwt} onMetadataUpdated={onMetadataUpdated} />,
						<StepThree data={data} wallets={connectedWallets} jwt={jwt} onMetadataUpdated={onMetadataUpdated} removeWallet={removeWallet} />,
						<StepFour data={data} discordUser={discordUser} onConnectDiscord={onConnectDiscord} jwt={jwt} onMetadataUpdated={onMetadataUpdated} />
					]}
					showHeader={true}
					showProgressIndicator={true}
				>
				</Modal>
				<Modal
					id="WalletConfig"
					type="overlay"
					steps={[
						<Wallets data={data} wallets={connectedWallets} jwt={jwt} onMetadataUpdated={onMetadataUpdated} chooseWallet={chooseWallet} />,
					]}
					showHeader={false}
					showProgressIndicator={false}
				>
				</Modal>
				<Modal
					id="HowItWorks"
					type="overlay"
					steps={[
						<HowOne />,
						<HowTwo />,
						<HowThree />,
						<HowFour />,
						<HowFive />,
					]}
					showHeader={true}
					showProgressIndicator={true}
				>
				</Modal>
				<Modal
					id="Republic"
					type="overlay"
					steps={[
						<Republic />
					]}
					showHeader={false}
					showProgressIndicator={false}
				>
				</Modal>
				<Modal
					id="KYC"
					type="overlay"
					steps={[
						<KYC />
					]}
					showHeader={false}
					showProgressIndicator={false}
				>
				</Modal>
				<Modal
					id="Burn"
					type="overlay"
					steps={[
						<Burn
							currentTokens={tokensAggregated}
							tokens={totalBurnableTokens}
							value={totalBurnableTokensValue}
							burns={burnableTokens}
							onEscrow={onEscrow}
							burnableParams={burnableParams}
							onBurnComplete={onBurnComplete}
							isTxProcessing={isTxProcessingModal}
							setIsTxProcessing={setIsTxProcessingModal}
							user={data}
						/>
					]}
					showHeader={false}
					showProgressIndicator={false}
					showCloseButton={!isTxProcessingModal}
				>
					{/* Burn Ritual Content */}
				</Modal>
			</div>
			<ToastContainer position="bottom-right" autoClose={5000} theme="dark" hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</div>
	);
}

const StepOne = ({ data, jwt, onMetadataUpdated }) => {

	const [username, setUsername] = useState((data?.data?.user_metadata?.nickname || data?.data?.nickname) || '');
	const [isSaved, setIsSaved] = useState(false);

	const updateUsername = async (data) => {
		try {
			let config = {
				method: "post",
				url: "https://burnitall.biginc.business/setmetadata",
				data: {
					token: jwt,
					metadata: [
						{
							type: "nickname",
							data: data,
						},
					],
				},
			};

			const response = await axios.request(config);
			onMetadataUpdated();
			setIsSaved(true);
			toast.success("Saved Username!")
		} catch (error) {
			console.log("Error:", error);
			setIsSaved(false);
			toast.success("Error. Please try again later.")
		}
	};

	const handleChange = (event) => {

		let newUsername = event.target.value;
		newUsername = newUsername.replace(/\s+/g, '');

		if (newUsername.length > 20) {
			newUsername = newUsername.substring(0, 20);
		}

		if (isSaved) {
			setIsSaved(false);
		}

		setUsername(event.target.value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		updateUsername(username)
	};

	return (
		<div id="StepOne" className="step">
			<h3>What would you like to be called?</h3>
			<form onSubmit={handleSubmit} className="form">
				<label>
					Username
					<div className="input-wrapper">
						<input
							type="text"
							value={username}
							onChange={handleChange}
							className="username-input"
						/>
						{isSaved && <i className="fa fa-check check-icon"></i>}
					</div>
					<div className="controls">
						<input type="submit" className="button" value="Save Username" />
					</div>
				</label>
			</form>
		</div>
	);
}
const StepTwo = ({ data, jwt, onMetadataUpdated }) => {
	const [isSaved, setIsSaved] = useState(false);
	const [bio, setBio] = useState(data.data.user_metadata.bio || data.data.bio || '');

	const updateBio = async (data) => {
		try {
			let config = {
				method: "post",
				url: "https://burnitall.biginc.business/setmetadata",
				data: {
					token: jwt,
					metadata: [
						{
							type: "bio",
							data: data,
						},
					],
				},
			};

			const response = await axios.request(config);
			onMetadataUpdated();
			setIsSaved(true);
			toast.success("Saved Bio!")
		} catch (error) {
			console.log("Error:", error);
			setIsSaved(false);
			toast.success("Error. Please try again later.")
		}
	};

	const handleChange = (event) => {
		setBio(event.target.value);
		if (isSaved) {
			setIsSaved(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		updateBio(bio)
	};
	return (
		<div id="StepTwo" className="step">
			<h3>Customize Your Truth Profile</h3>

			<div className="user-profile-banner">
				<button id="Edit-Profile-Banner" className="icon-button"><i className="far fa-pencil"></i></button>
				<LazyLoadImage
					alt={"User Banner"}
					src={userBanner}
				/>
			</div>

			<form onSubmit={handleSubmit} className="form">
				<label>
					Your Bio
					<div className="input-wrapper">
						<textarea
							type="text"
							value={bio}
							onChange={handleChange}
							rows="4"
						/>
						{isSaved && <i className="fa fa-check check-icon"></i>}
					</div>
					<div className="controls">
						<input type="submit" className="button" value="Save Bio" />
					</div>
				</label>
			</form>
		</div>
	);
}
const StepThree = ({ data, wallets, jwt, removeWallet }) => {
	return (
		<div id="StepThree" className="step">
			<h3>Connect your wallet(s)</h3>
			<div className="step-copy">
				<p>Connect as many wallets as you want, but you will need to interact with your tokens on a wallet by wallet basis.</p>

				{wallets && wallets.length > 0 && (
					<>
						<div className="wallets">
							<h4>Connected Wallets:</h4>
							<ul>
								{wallets.map((wallet, index) => (
									<li key={index}>
										{wallet}
										<button onClick={() => removeWallet(wallet)} className="remove-wallet-button">
											<i className="fa fa-times" aria-hidden="true"></i>
										</button>
									</li>
								))}
							</ul>
						</div>
						<div className="wallets">
							<h4>Active Connection:</h4>
						</div>
					</>
				)}

				<ConnectWallet
					className="connect-wallet"
					id="Connect-Wallet"
					btnTitle={"Connect Wallet"}
					modalTitleIconUrl={""}
					showThirdwebBranding={false}
					modalTitle={""}
					switchToActiveChain={true}
					modalSize={"compact"}
					welcomeScreen={{ title: "" }}
					auth={{
						loginOptional: false,
					}}
				/>
			</div>
		</div>
	);
}
const StepFour = ({ discordUser, onConnectDiscord, jwt, onMetadataUpdated }) => {

	return (
		<div id="StepFour" className="step">
			<h3>Connect to your socials</h3>
			<div className="step-copy">

				{!discordUser ? (
					<>
						<p>Connect your socials and we'll give you <strong className="stronge4">25 bonus points</strong> in the Truth Raffle for playing along.</p>
						<div className="button-group">
							<button id="Connect-To-Discord" className="button" onClick={onConnectDiscord}>
								<i className="fab fa-discord"></i> Connect to Discord
							</button>
						</div>
					</>
				) : (
					<>
						<p>You have successfully connected your discord!</p>
						<div className="discord-profile">
							<img src={`https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`} alt={`${discordUser.username}'s Avatar`} className="discord-avatar" />
							<span className="discord-username">{discordUser.username}</span>
						</div>
					</>
				)}
			</div>

			{/* <div className="controls">
			<button className="button">View your profile</button>
		</div> */}
		</div>
	);
}

const Wallets = ({ data, wallets, jwt }) => {
	const { getAccessTokenSilently } = useAuth0();

	const [selectedWallet, setSelectedWallet] = useState(() => {
		const savedWallet = localStorage.getItem('selectedWallet');
		return savedWallet || data?.data?.user_metadata?.nickname;
	});

	useEffect(() => {
		localStorage.setItem('selectedWallet', selectedWallet);
	}, [selectedWallet]);

	const fetchApi = async () => {
		try {
			const token = await getAccessTokenSilently();
			return token;
		} catch (error) {
			console.error("Failed to retrieve access token", error);
		}
	};

	const updateSelectedWallet = async (wallet) => {

		const token = await fetchApi();

		try {
			let config = {
				method: "post",
				url: "https://burnitall.biginc.business/setmetadata",
				data: {
					token: token,
					metadata: [
						{
							type: "nickname",
							data: wallet,
						},
					],
				},
			};

			await axios.request(config);
			setSelectedWallet(wallet);
			toast.success("Saved selected wallet");
		} catch (error) {
			console.log("Error:", error);
			toast.error("Error. Please try again later.");
		}
	};

	return (
		<div className="step">
			{/* <h2 style={{ fontSize: 80 }}>RECEIVE AIRDROP</h2> */}
			<p>Select the wallet you want the Burned Collection NFTs to be airdropped to. If you don’t select a wallet by April 17th at midnight, they will be airdropped to the wallet you burned with. You can connect a new wallet below:</p>
			{wallets?.length > 0 && (
				<div className="wallets">
					<h4>Choose a wallet:</h4>
					<ul>
						{wallets.map((wallet, index) => (
							<button
								className={`button black ${wallet === selectedWallet ? 'selected main-action' : ''}`}
								key={index}
								onClick={() => updateSelectedWallet(wallet)}
							>
								{`${wallet.substring(0, 4)}...${wallet.substring(wallet.length - 4)}`}
							</button>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};



// how it works
const HowOne = () => {
	return (
		<div id="howOne" className="step">
			<div className="burn-hero step-graphic">
				<img src={burnHero} alt="burn hero"></img>
			</div>
			<h2>Welcome!</h2>
			<p>You have until March 27th, 2024 at 11:59 pm PST to participate. We're going to show you around. Ready to go?</p>
		</div>
	);
}
const HowTwo = () => {
	return (
		<div id="HowTwo" className="step">
			<div className="example-token step-graphic">
				<img src={exampleTokens} alt="example tokens"></img>
			</div>
			<h2>Check NFT Value</h2>
			<p>Check your NFTs' value by hovering or clicking on them. Look for the fire emoji to know the burn value. You can review the <a href="https://blog.trutharts.com/operation-resurrection-faqs" target="_blank" className="a-tag">FAQ</a> for more info on how the NFTs are valued.</p>
		</div>
	);
}
const HowThree = () => {
	const { closeModal } = useModal();
	const close = () => {
		closeModal("HowItWorks")
	}
	return (
		<div id="HowThree" className="step">
			<div className="equity-meter step-graphic">
				<img src={equityMeter} alt="equity meter"></img>
			</div>
			<h2>The Equity Meter</h2>
			<p>Your NFT value qualifies you for different levels of future equity rights. Check the meter to see what bucket you fall in.</p>
			<ul className="flame-list">
				<li>For example, if the value of your NFTs is $1,500, then you fall between the $1,000 and $2,500 tiers and would qualify for $1,000 of future equity rights.</li>
			</ul>
		</div>
	);
}
const HowFour = () => {
	const { closeModal } = useModal();
	const close = () => {
		closeModal("HowItWorks")
	}
	return (
		<div id="HowThree" className="step">
			<div className="example-token step-graphic">
				<img src={selectedTokens} alt="selected tokens"></img>
			</div>
			<h2>Burn your Tokens</h2>
			<p><strong>Choose which NFTs you want to burn.</strong> You will send them to a holding contract and you can cancel the action until March 27, 2024.</p>
			<ul className="flame-list">
				<li>Even if you cancel, the NFTs cannot be accessed for 30-60 days. After March 27, 2024 the action can't be reversed.</li>
				<li>After burning, you will receive a soul-bound NFT with the same art as part of the <strong>Burned Collection</strong>.</li>
			</ul>
		</div>
	);
}
const HowFive = () => {
	const { closeModal } = useModal();
	const close = () => {
		closeModal("HowItWorks")
	}
	return (
		<div id="HowThree" className="step">
			<h2>Don't forget, you need to create a <a href="https://republic.com/truth-arts" target="_blank" className="a-tag">Republic</a> account.</h2>
			<ul className="flame-list">
				<li>You are subject to a KYC process&mdash;if you don't pass, your NFT will be returned to you.</li>
				<li>Your SAFE and all communications will be sent through your Republic account.</li>
			</ul>
			<button className="button main-action" onClick={close}>I understand, time to burn!</button>
		</div>
	);
}

// republic cta
const Republic = () => {
	const { closeModal } = useModal();

	const close = () => {
		closeModal("Republic")
	}

	return (
		<div id="Republic-Reminder" className="step">
			<h2>You're Not Done Yet&hellip;</h2>
			<ul className="flame-list">
				<li>You need to create a <a href="https://republic.com/truth-arts" target="_blank" className="a-tag">Republic</a> account. Make sure you use the same email address that you used to log into this website.</li>
				<li>You need to complete the KYC process with Republic before March 27th, 2024.</li>
				<li>If you do not create a Republic account or complete KYC, your NFTs will be returned to you.</li>
			</ul>
			<div className="button-group">
				<a className="button main-action" href="https://republic.com/truth-arts" target="_blank">Go To Republic</a>
				<button className="button" onClick={close}>I'm all set</button>
			</div>
		</div>
	);
}

// republic cta no burn
const KYC = () => {
	const { closeModal } = useModal();

	const close = () => {
		closeModal("KYC")
	}

	return (
		<div id="Republic-Reminder" className="step">
			<h2>Did you KYC?</h2>
			<ul className="flame-list">
				<li>You need to create a <a href="https://republic.com/truth-arts" target="_blank" className="a-tag">Republic</a> account. Make sure you use the same email address that you used to log into this website.</li>
				<li>You need to complete the KYC process with Republic before March 27th, 2024.</li>
				<li>If you do not create a Republic account or complete KYC, your NFTs will be returned to you.</li>
			</ul>
			<div className="button-group">
				<a className="button main-action" href="https://republic.com/truth-arts" target="_blank">Go To Republic</a>
				<button className="button" onClick={close}>I'm all set</button>
			</div>
		</div>
	);
}

const Burn = ({ currentTokens, tokens, value, burns, onEscrow, burnableParams, onBurnComplete, isTxProcessing, setIsTxProcessing, user }) => {
	const [isRunning, setIsRunning] = useState(false);
	const [txMessage, setTxMessage] = useState("");
	const [checkboxState, setCheckboxState] = useState({
		checkbox1: false,
		checkbox2: false,
		checkbox3: false,
		checkbox4: false
	});

	const burnItAll = "0x4aB2db7a957E02ab1830793534B07E1898eE253e";

	const { openModal, closeModal } = useModal();
	const address = useAddress();
	const signer = useSigner();
	const { getAccessTokenSilently } = useAuth0();

	const handleCheckboxChange = (event) => {
		setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
	};

	const collectionNames = {
		"0x895688bf87d73cc7da27852221799d31b027e300": {
			name: "BiG Inc",
			filter: "Filter-Big",
		},
		"0x7b390a46c868ccebf2730121faf777129b47c97c": {
			name: "BiG Inc Letters",
			filter: "Filter-Big",
		},
		"0x8c6def540b83471664edc6d5cf75883986932674": {
			name: "goblintown",
			filter: "Filter-Gobs",
		},
		"0x279c53df917579bb454b61555982dac1750187d9": {
			name: "grumpls",
			filter: "Filter-Grumpls",
		},
		"0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93": {
			name: "mcgoblin",
			filter: "Filter-Other",
		},
		"0xface84a186f597c31a6e119b4114c42f28c21c95": {
			name: "akcessores",
			filter: "Filter-Other",
		},
		"0x8cb05890b7a640341069fb65dd4e070367f4d2e6": {
			name: "Illuminati",
			filter: "Filter-Mids",
		},
		"0xbfc5f30e9da14d9506a0ea1eea71e2bf6bb0c3f9": {
			name: "Illuminati Passes",
			filter: "Filter-Other",
		},
		"0x3266b70962a61f1917f79c387749e3bd95a9ff52": {
			name: "Believer Pass",
			filter: "Filter-Other",
		},
		"0x7912a656ece2bc669d4116ad8e9495f722d92d76": {
			name: "UNDAO",
			filter: "Filter-Other",
		},
		"0x920cc6bf85c293b778fb22c79a391c8b014800dd": {
			name: "Illuminati Community",
			filter: "Filter-Other",
		},
		"0x50b4a624b297198debedf908940f7aacfba60180": {
			name: "Illuminary",
			filter: "Filter-Other",
		},
		"0x2894e6212493325da5e838450ac6b29f42a06f46": {
			name: "goblinaries",
			filter: "Filter-Other",
		},
		"0x02dba5957bd6c872be6a96d2d7ea80c773cf7784": {
			name: "PeePee Goblins",
			filter: "Filter-Other",
		},
		"0x9777f2c85713f05356da1b43bce02b30bda1bf69": {
			name: "Summer Solstice 2022",
			filter: "Filter-Other",
		},
		"0xe25f0fe686477f9df3c2876c4902d3b85f75f33a": {
			name: "DAO",
			filter: "Filter-Other",
		},
		"0x9ef8750c72061edbeeef4beb1aceee5b5a63748a": {
			name: "The 187",
			filter: "Filter-Mids",
		},
		"0x67df41edf21e1c581a77e939f60db7baa5e96993": {
			name: "Kryptic Kids",
			filter: "Filter-Other",
		},
		"0x66dab8a88b7ca020a89f45380cc61692fe62e7ed": {
			name: "Cryptoface",
			filter: "Filter-Other",
		},
		"0x412b986a5a8e2e50bdafe7595c1f066c654ab1dc": {
			name: "Test 721 Collection",
			filter: "Filter-Mids",
		},
		"0xf062dbe7eba009dc9521ec9c2d97445c1160e763": {
			name: "Test 1155 Collection",
			filter: "Filter-Other",
		},
		"0x7fbb00d744b5975e870191924076af620039b169": {
			name: "Another Test 721 Collection",
			filter: "Filter-Mids",
		},
		"0xd4d1b6a29321754a8752def9cda6735fbfb8c29f": {
			name: "Another Test 1155 Collection",
			filter: "Filter-Other",
		},
	};

	const getCollectionName = (contract) => {
		const normalizedContract = contract.toLowerCase();
		return collectionNames[normalizedContract]?.name || "Unknown Collection";
	};

	const allCheckboxesChecked = checkboxState.checkbox1 && checkboxState.checkbox2 && checkboxState.checkbox3 && checkboxState.checkbox4;

	const buckets = [100, 1000, 2500, 5000, 10000, 25000, 50000];
	const currentTotalValue = currentTokens.reduce((total, token) => token.escrow ? total + (token.price * token.amount) : total, 0);
	const newTotalValue = Math.min(currentTotalValue + value, 50000);
	const findBucketIndex = (value) => buckets.findIndex(bucket => value < bucket);
	const currentBucketIndex = findBucketIndex(currentTotalValue) === -1 ? buckets.length - 1 : findBucketIndex(currentTotalValue) - 1;
	const newNextBucketIndex = newTotalValue >= 50000 ? buckets.length - 1 : findBucketIndex(newTotalValue) - 1;
	const currentBucketRange = currentBucketIndex === buckets.length - 1 ? `$${buckets[currentBucketIndex]} and above` : `$${buckets[currentBucketIndex]} - $${buckets[currentBucketIndex + 1]}`;
	const newNextBucketRange = newNextBucketIndex === buckets.length - 1 ? `$${buckets[newNextBucketIndex]} and above` : `$${buckets[newNextBucketIndex]} - $${buckets[newNextBucketIndex + 1]}`;
	const firstTimeMessage = currentBucketIndex < 0 || currentTotalValue < 100;

	useEffect(() => {
		if (currentTotalValue >= 50000) {
			toast.warning("You have reached $50,000 of future equity rights, which is the maximum value any one participant in Operation Resurrection can earn. Burning more NFTs will not bring any additional value to you. More chances to burn will come in Phase II of Operation Resurrection.")
		}
	}, [currentTotalValue]);

	const escrow = () => {
		setIsRunning(true);
		run();
	}

	const getSig = async () => {

		const token = await fetchApi();

		try {

			let config2 = {
				method: 'post',
				url: 'https://burnitall.biginc.business/getsig',
				withCredentials: true,
				data: {
					token: token,
				},
			};

			const response2 = await axios.request(config2);

			return response2.data.data;

		} catch (error) {
			console.log('Error:', error);
		}
	}

	const ReadContract = {
		abi: [
			"function isApprovedForAll(address owner, address operator) external view returns (bool)",
			"function setApprovalForAll(address operator, bool approved) external",
			"function ownerOf(uint256 tokenId) external view returns (address owner)"
		]
	};

	const ReadContractBurn = {
		abi: [
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "address",
								"name": "token",
								"type": "address"
							},
							{
								"internalType": "uint256[]",
								"name": "tokenId",
								"type": "uint256[]"
							},
							{
								"internalType": "uint256[]",
								"name": "amount",
								"type": "uint256[]"
							}
						],
						"internalType": "struct TokenParams[]",
						"name": "params",
						"type": "tuple[]"
					},
					{
						"internalType": "bytes",
						"name": "signature",
						"type": "bytes"
					}
				],
				"name": "burnTokens",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			}
		]
	};


	const checkAndRequestApprovals = async () => {
		for (const param of burnableParams) {
			const isApproved = await checkContractApproval(param.token);
			if (!isApproved) {
				await runContractApproval(param.token);
			}
		}
	};

	async function checkContractApproval(contract) {

		const RPC = "https://eth-mainnet.g.alchemy.com/v2/x2clG0iamQo50Ymz3JD1IuTkvf0MGRO1";
		const provider = new ethers.providers.JsonRpcProvider(RPC);
		const CONTRACT_ADDRESS = new ethers.Contract(contract, ReadContract.abi, provider);

		var checkApproval = await CONTRACT_ADDRESS.isApprovedForAll(address, burnItAll)
		return checkApproval;
	}

	const [txHash, setTxHash] = useState('');

	const runContractApproval = async (contract) => {
		if (!signer) {
			console.error("Signer is not available");
			return;
		}

		const CONTRACT_ADDRESS = new ethers.Contract(contract, ReadContract.abi, signer);
		try {
			setIsTxProcessing(true);
			setTxMessage(`Confirm approval for ${getCollectionName(contract)}.`);
			setTxHash("")
			const runApproval = await CONTRACT_ADDRESS.setApprovalForAll(burnItAll, true);
			setTxHash(runApproval.hash)
			setTxMessage(`Approving ${getCollectionName(contract)}.`);
			await runApproval.wait();
			toast.success(`${getCollectionName(contract)} approved`);
			setTxMessage(`${getCollectionName(contract)} approved`);
		} catch (error) {
			console.error("Error during contract approval:", error);
			setTxMessage(`Error during contract approval. Please refresh and try again.`);
			setIsTxProcessing(false);
		}
	};

	const run = async () => {

		if (!signer) {
			console.error("Signer is not available");
			return;
		}

		const CONTRACT_ADDRESS = new ethers.Contract(burnItAll, ReadContractBurn.abi, signer);

		await checkAndRequestApprovals();

		let sig = await getSig();

		if (!sig) {
			toast.error("Too quick! Try again.");
			return;
		}

		setTxMessage(`Approvals Done. Confirm to start transaction`);
		setTxHash('');

		try {
			const txResponse = await CONTRACT_ADDRESS.burnTokens(burnableParams, sig);
			setIsTxProcessing(true);
			setTxMessage(`Transaction Pending.`);
			setTxHash(txResponse.hash)

			setTimeout(() => {
				setTxMessage(`Almost there! This can take up to 60 seconds. Please don't refesh your screen`);
				setTxHash('');

				toast.info("Please don't refresh your screen", {
					position: "top-center",
					autoClose: 60000,
					pauseOnFocusLoss: false,
					pauseOnHover: false,
					draggable: false,
					closeOnClick: false,
					closeButton: false,
				});

				setTimeout(() => {
					closeModal("Burn");
					openModal("Republic", true);
					setIsTxProcessing(false);
					onBurnComplete(value);
				}, 60000);
			}, 25000);

		} catch (error) {
			console.error("Burn failed:", error);
			closeModal("Burn");
			setIsTxProcessing(false);
			toast.warning("Transaction Cancelled/Failed. If you are having transaction issues, try to disconnect and reconnect your wallet on the top of the page");
			setTxMessage(`Transaction failed. Please refresh and try again. If you continue to have issues, try to disconnect and reconnect your wallet.`);
		}
	};

	const fetchApi = async () => {
		try {
			const token = await getAccessTokenSilently();
			return token;
		} catch (error) {
			console.error("Failed to retrieve access token", error);
		}
	};

	return (
		<div id="Burn-List" >
			{/* token grid */}
			<div className="token-burn-label">
				<div className="flame">
					<img src={flameBig} alt="flame"></img>
				</div>
				<h1>Burn<br></br>summary</h1>
			</div>
			<div className="token-burn-list">
				{/* token */}
				{burns.map((burn, index) => (
					<div key={index} className="cart-token">
						<div className="token">
							<img src={burn.image} alt={`Token ${burn.id}`} />
						</div>
						<div className="token-burn-info">
							<h3 className="token-burn-value"><span className="value-label">NFT burn value</span>${burn.dynamicPrice.toFixed(2)}</h3>
							<h3 className="token-burn-amount"><span className="value-label">token(s)</span>{burn.dynamicAmount}</h3>
						</div>
					</div>
				))}
			</div>
			{/* token summary */}
			{
				currentTotalValue < 50000 ? (
					<>
						{!isTxProcessing ? (
							<div className="balance-info">
								<div className="balance-totals">
									<h3 className="token-burn-total"><span className="value-label">Total burn value</span>${value.toLocaleString()}</h3>
									{/* <h3 className="token-burn-summary"><span className="value-label">total burning</span>{tokens}</h3> */}

								</div>
								<div className="wiggle-divide">
									<img src={wiggle} alt="wiggle"></img>
								</div>
								<div className="burn-explanation">
									{newNextBucketIndex < 0 ? (
										<p>You ({user.data.email}) are not in a bucket yet. If you burn {tokens === 1 ? 'this token' : 'these tokens'}, you will still be at the minimum bucket threshold of $100.</p>
									) : firstTimeMessage ? (
										<p>You ({user.data.email}) are not in a bucket yet. If you burn {tokens === 1 ? 'this token' : 'these tokens'}, you will be in <strong>bucket {newNextBucketIndex + 1} ({newNextBucketRange})</strong> with ${newTotalValue >= 50000 ? "50,000" : newTotalValue.toFixed(2).toLocaleString()} NFT burn value, which means you will be receiving a ${buckets[newNextBucketIndex] ? buckets[newNextBucketIndex].toLocaleString() : newTotalValue.toLocaleString()} SAFE.</p>
									) : (
										<>
											{/* <p>You ({user.data.email}) are currently in <strong>bucket {currentBucketIndex + 1} ({currentBucketRange})</strong> with ${currentTotalValue >= 50000 ? "50,000" : currentTotalValue.toFixed(2).toLocaleString()} NFT burn value.</p> */}
											<p>By burning {tokens === 1 ? 'this token' : 'these tokens'}, you ({user.data.email}) will have burned a total of ${newTotalValue >= 50000 ? "50,000" : newTotalValue.toFixed(2).toLocaleString()} in NFTs, meaning you will receive <strong>${buckets[newNextBucketIndex] ? buckets[newNextBucketIndex].toLocaleString() : newTotalValue.toLocaleString()} in future equity rights.</strong></p>
											<p>If you have any questions on the equity tiers, you can read the <a href="https://blog.trutharts.com/operation-resurrection-faqs" target="_blank" className="a-tag">FAQs</a>.</p>
										</>
									)}
								</div>
								<div className="checkboxes">
									<div className="wiggle-divide">
										<img src={wiggle} alt="wiggle"></img>
									</div>
									<label>
										<input
											type="checkbox"
											name="checkbox1"
											checked={checkboxState.checkbox1}
											onChange={handleCheckboxChange}
										/>
										{newNextBucketIndex < 0 ? (
											<p>I understand that by electing to burn my NFTs, I will not receive a SAFE since it is still below the $100 threshold.</p>
										) : (
											<p>I understand that by electing to burn my NFTs, I will be eligible to receive a SAFE valued at ${buckets[newNextBucketIndex] ? buckets[newNextBucketIndex].toLocaleString() : newTotalValue.toLocaleString()}, subject to my approval via Republic's KYC process</p>
										)}
									</label>
									<label>
										<input
											type="checkbox"
											name="checkbox2"
											checked={checkboxState.checkbox2}
											onChange={handleCheckboxChange}
										/>
										<p>I acknowledge and agree that I have read, understood and accept all of the terms and conditions contained in the <a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Terms+and+Conditions.pdf" target="_blank" rel="noopener noreferrer" className="a-tag">Terms and Conditions</a> and <a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Privacy+Policy_2.21.24.pdf" target="_blank" rel="noopener noreferrer" className="a-tag">Privacy Policy</a></p>
									</label>
									<label>
										<input
											type="checkbox"
											name="checkbox3"
											checked={checkboxState.checkbox3}
											onChange={handleCheckboxChange}
										/>
										I understand that I am sending my NFTs out of my wallet, and if I pass KYC, they will be burned after March 27, 2024, an action that is irreversible.
									</label>
									<label>
										<input
											type="checkbox"
											name="checkbox4"
											checked={checkboxState.checkbox4}
											onChange={handleCheckboxChange}
										/>
										I understand that if I do not pass KYC, or elect to cancel this process, I will be unable to access these NFTs until they are returned to me 30-60 days after March 27, 2024.
									</label>
								</div>
								<button
									className={`button main-action ${!allCheckboxesChecked || isTxProcessing ? 'disable-escrow' : ''}`}
									onClick={escrow}
									disabled={!allCheckboxesChecked || isRunning}
								>
									SEND
								</button>
							</div>
						) : (
							<div className="balance-info">
								<p>
									{txMessage}
									{txHash && (
										<a href={`https://etherscan.io/tx/${txHash}`} target="_blank" rel="noopener noreferrer" className="tx-hash">
											&nbsp;View Transaction
										</a>
									)}
									<span className="loading-dots"></span>
								</p>
							</div>
						)}
					</>
				) : (
					<div className="balance-info">
						<p>You have reached $50,000 of future equity rights, which is the maximum value any one participant in Operation Resurrection can earn. Burning more NFTs will not bring any additional value to you. More chances to burn will come in Phase II of Operation Resurrection.</p>
					</div>
				)
			}


		</div>
	);
};



export default Presentation;